import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { CardDeck, Col, Container, Image, Row, Button } from 'react-bootstrap';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import IntegrationCard from '../components/IntegrationCard/IntegrationCard';
import '../styles/technology.scss';
import { Helmet } from 'react-helmet';

function Technology() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Technology</title>
        <meta
          name='description'
          content='MetWatch can interface with existing weather station hardware,
          digital tools and business intelligence systems and databases to
          bring critical data together into one place'
        />
      </Helmet>
      <HeaderExpanded
        title='The MetWatch Platform &amp; Our Technology'
        image={process.env.GATSBY_S3_BUCKET_ASSETS + 'images/crops_net.jpg'}
      />
      <Container className='py-5'>
        <Row>
          <Col md={6} className='mb-3 mb-md-0'>
            <p className='hp-font-28'>
              MetWatch is an industry-leading digital platform for weather,
              irrigation, and pest &amp; disease risk decision support.
            </p>
            <p>
              The MetWatch Platform provides local weather insight, powerful
              forecasts, and agricultural weather data to help{' '}
              <Link to='/disease-models'>
                manage pest &amp; disease pressure
              </Link>
              , plan spraying and irrigation, aid crop planting, and to achieve
              sustainability goals.
            </p>
            <p>
              MetWatch is highly extendable and we can leverage our powerful API
              to build new tools and custom solutions to meet your
              organisation's needs.
            </p>
            <Row className='pt-2 px-0 d-flex justify-content-center'>
              <Button as={Link} to={'/demo'}>
                Request a Demo
              </Button>
            </Row>
          </Col>
          <Col
            md={6}
            className='d-flex justify-content-center align-items-center'
          >
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                'images/weather_station.svg'
              }
              alt='Weather Station Icon'
              width='250'
            />
          </Col>
        </Row>
      </Container>
      <hr />
      <Container className='py-5 d-flex flex-column align-items-center'>
        <Container as='h2' className='hp-title pb-4 text-center'>
          A Powerful Digital Platform
        </Container>
        <Row className='text-center'>
          <Col className='mb-4 mb-md-0'>
            <p>
              MetWatch is powered by a modern technology stack that is highly
              scalable and backed by proven and secure cloud-based
              infrastructure.
            </p>
            <p>
              MetWatch is hardware and system agnostic which means we can
              interface with a wide range of existing weather station hardware,
              digital tools and business intelligence systems and databases to
              bring critical data and analytics together into one place.
            </p>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        className='py-5 hp-page--blue-grey d-flex flex-column align-items-center'
      >
        <Container as='h2' className='hp-title pb-4 text-center'>
          How it works
        </Container>
        <Row>
          <Col>
            <Container className='d-flex justify-content-center mb-4'>
              <ul className='hp-feature-list'>
                <li>
                  MetWatch combines sensor data, weather station feeds,
                  phenology, and third-party information from a range of sources
                </li>
                <li>
                  Data is collected over mobile networks or provided by sampling
                  and monitoring apps and securely stored in cloud
                  infrastructure and processed
                </li>
                <li>
                  Models and tools powered by the data are accessed by growers,
                  agronomists, and researchers through web and mobile platforms
                </li>
              </ul>
            </Container>
          </Col>
        </Row>

        <Row>
          <Col>
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                'images/weather_process.svg'
              }
              alt='Weather Process'
              className='d-block mx-auto'
              width='700'
              fluid
            />
          </Col>
        </Row>
        <Container as='h2' className='hp-title pb-4 text-center mt-5'>
          Platform Capabilities
        </Container>
        <Container className='d-flex justify-content-center'>
          <ul className='hp-feature-list'>
            <li>
              Hourly weather forecasts up to 15-days for key agricultural
              variables
            </li>
            <li>
              Actual data from a robust network of scientific-grade weather
              stations in key growing areas
            </li>
            <li>
              A comprehensive historical dataset stretching back up to 20 years
            </li>
            <li>
              A user-friendly and intuitive design system for use in the field
              or in the office
            </li>
            <li>
              A powerful Internal API system for interfacing with existing
              systems
            </li>
            <li>
              Connect spray diary reports, total usage reports, agrichemical
              residues, and pheromone trap monitoring
            </li>
            <li>
              Integration with a range of third-party providers and hardware
              makers
            </li>
          </ul>
        </Container>
      </Container>

      <Container fluid className='py-5 d-flex flex-column align-items-center'>
        <Container as='h2' className='hp-title pb-5 text-center'>
          Our Managed Weather Station Network
        </Container>
        <Container className=''>
          <Row>
            <Col xs={12} sm={7} className='pr-0 pr-md-5 mb-3 mb-md-0'>
              <p className='mb-5 hp-font-28'>
                We maintain a network of scientific &amp; research-grade weather
                stations located in key growing areas
              </p>
              <p>
                We take weather data from our well established and expanding
                network of over 100 scientific-grade weather stations, located
                on private orchards, farms, vineyards, and research sites in the
                main horticultural and agricultural growing areas of New
                Zealand. We are also able to interpret weather data from a range
                of other sources including grower-owned weather stations to
                provide a full picture of the microclimates and other factors
                that are important to making decisions.
              </p>
              <p>
                Our network of weather stations measure key variables including
                air temperature, rainfall, wind speed and direction, humidity,
                calculated GDD and chill units, alongside other parameters such
                as leaf wetness, dew point and evapotranspiration. All data is
                updated and fed into a cloud-based database for rendering,
                analysis and display with our digital platform tools.
              </p>
              <Button as={Link} to={'/weather-data'} className='mt-2'>
                Our NZ Weather Data Network
              </Button>
            </Col>

            <Col
              xs={12}
              sm={5}
              className='d-flex justify-content-center align-items-center'
            >
              <div>
                <Image
                  src={
                    process.env.GATSBY_S3_BUCKET_ASSETS +
                    'images/weather_station.jpg'
                  }
                  alt='Weather Station'
                  width='540'
                  className='mx-auto'
                  fluid
                />
                <p className='hp-text--note pt-3 text-center'>
                  Scientific-grade weather station on the HortPlus network.
                </p>
              </div>
            </Col>
          </Row>
          <Row
            md={6}
            className='pt-5 d-flex justify-content-center align-items-center text-center'
          >
            <Col xs={6} md={3}>
              <a href='https://harvest.com/'>
                <Image
                  src={
                    process.env.GATSBY_S3_BUCKET_ASSETS +
                    'brands/harvest_logo.png'
                  }
                  alt='Harvest Weather Stations'
                  fluid
                  width='200'
                  className='p-2'
                />
              </a>
            </Col>
            <Col xs={6} md={3}>
              <a href='https://metris.nz/'>
                <Image
                  src={
                    process.env.GATSBY_S3_BUCKET_ASSETS +
                    'brands/metris_logo.png'
                  }
                  alt='Metris'
                  fluid
                  width='100'
                  className='p-2'
                />
              </a>
            </Col>
            <Col xs={6} md={3}>
              <a href='https://www.campbellsci.com/'>
                <Image
                  src={
                    process.env.GATSBY_S3_BUCKET_ASSETS +
                    'brands/campbell_logo.png'
                  }
                  alt='Campbell Scientific'
                  fluid
                  className='p-2'
                  width='200'
                />
              </a>
            </Col>
            <Col xs={6} md={3}>
              <a href='https://www.davisinstruments.com/'>
                <Image
                  src={
                    process.env.GATSBY_S3_BUCKET_ASSETS +
                    'brands/davis_instruments_logo.png'
                  }
                  alt='Davis Instruments'
                  fluid
                  className='p-2'
                  width='180'
                />
              </a>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        fluid
        className='hp-page--blue-grey d-flex flex-column align-items-center py-5'
      >
        <h1 className='hp-title hp-title--subtitle hp-title--subtitle-twothirds d-block text-center pb-5'>
          Connect Weather Stations &amp; Hardware
        </h1>
        <CardDeck className='hp-carddeck-width justify-content-center'>
          <IntegrationCard type={'harvest'} />
          <IntegrationCard type={'campbell'} />
          <IntegrationCard type={'metris'} />
          <IntegrationCard type={'ricado'} />
        </CardDeck>
        <h1 className='hp-title hp-title--subtitle hp-title--subtitle-twothirds d-block text-center pb-5'>
          Data Integrations and Modules
        </h1>
        <CardDeck className='hp-carddeck-width justify-content-center'>
          <IntegrationCard type={'property'} />
          <IntegrationCard type={'residues'} />
          <IntegrationCard type={'irrigation'} />
          <IntegrationCard type={'diseasemodels'} />
          <IntegrationCard type={'dailyreports'} />
        </CardDeck>
        <h1 className='hp-title hp-title--subtitle hp-title--subtitle-twothirds d-block text-center pb-5'>
          Spray &amp; Monitoring Integrations
        </h1>
        <CardDeck className='hp-carddeck-width justify-content-center'>
          <IntegrationCard type={'sprayview'} />
          <IntegrationCard type={'traplog'} />
          <IntegrationCard type={'sprayplanmanager'} />
          <IntegrationCard type={'spraylog'} />
        </CardDeck>
      </Container>
    </>
  );
}

export default Technology;
